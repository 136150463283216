import React, { useState } from "react";
import { useContent } from "fusion-content";
import { useFusionContext } from "fusion-context";
import { useChainContext } from "~/shared-components/ChainContext";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { applyMobilePresets } from "~/components/features/fronts/flex-feature/utilities/mobile-presets";
import useApi from "~/components/utilities/use-api";
import StoryCards from "./_children/StoryCards";
import { feedType } from "./utilities/index";
import defaults from "./utilities/custom-field-defaults";
import Feed from "./_children/Feed";
import { noContentConfig } from "./default.helpers";
import { FlexFeaturePropTypes } from "~/proptypes/flex-feature";
import {
  getSecureUserId,
  getUserId
} from "~/components/utilities/login-details";
import {
  getRTEModuleCategory,
  getRTEModulePosition,
  getRTEParentAttrs,
  getRTESurface
} from "~/components/features/fronts/utilities/rte";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";

/**
 * Flex Feature
 * @param {string} id
 * @param {object} customFields
 * @param {bool} noGrid
 * @param {object} context
 * @return {Component}
 */
const FlexFeature = (props) => {
  const { noGrid, id, context, curationIndices, rootCustomFields } = props;
  let { customFields } = props;
  const chainCtx = useChainContext() || {};

  const { bp } = useBreakpoints();

  customFields = applyMobilePresets({
    customFields: { ...defaults, ...customFields },
    chainCtx
  });

  const { flexFeatureContentConfig } = customFields;

  const fusionContext = useFusionContext();

  const { isAdmin } =
    context !== undefined && context.isAdmin !== undefined
      ? context
      : fusionContext;
  const overrides = customFields;

  const [userId, setUserId] = useState();
  const [secureUserId, setSecureUserId] = useState();
  React.useEffect(() => {
    setUserId(getUserId());
    setSecureUserId(getSecureUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { type, contentConfig, clientSideConfig } = feedType({
    flexFeatureContentConfig,
    userId,
    secureUserId,
    bp,
    isAdmin
  });

  const isClientSideContent = !!clientSideConfig;

  const isLiveTicker =
    customFields.liveTickerHide !== true && customFields.liveTickerNum > 0;

  // We want to automatically update feeds in the admin every three min.
  const pollInterval = type === "feed" || isLiveTicker ? 180000 : 0;
  const finalConfig = !isClientSideContent ? contentConfig : {};

  // fetch content from pcs
  let fetchedContent = useContent(
    Object.keys(finalConfig).length ? finalConfig : noContentConfig,
    pollInterval
  );

  const { useHook = () => useApi(), query } = clientSideConfig || {};

  // fetch content avoiding pcs
  const { content: clientSideContent, state: clientSideState } = useHook(query);
  if (isClientSideContent) {
    // Make sure { isLoading: true } is returned when loading
    fetchedContent = /ERROR|SUCCESS/.test(clientSideState)
      ? clientSideContent
      : { isLoading: true };
  }

  /**
   * Set Real-time Events (RTE) attributes on the parent table.
   */
  const tableCtx = useTableLayoutContext();
  const { setAttrs } = tableCtx;
  const layoutCtx = useSandwichLayoutContext();
  const modulePosition = getRTEModulePosition(layoutCtx, tableCtx);
  const moduleCategory = getRTEModuleCategory(chainCtx, tableCtx);
  const surface = getRTESurface(
    clientSideContent?.items,
    context,
    modulePosition
  );
  const attrsRTE = React.useMemo(
    () =>
      getRTEParentAttrs(
        clientSideContent?.items,
        surface,
        moduleCategory,
        modulePosition,
        isClientSideContent
      ),
    [
      clientSideContent?.items,
      surface,
      moduleCategory,
      modulePosition,
      isClientSideContent
    ]
  );
  React.useEffect(() => {
    setAttrs(attrsRTE);
  }, [attrsRTE, setAttrs]);

  switch (type) {
    case "feed":
      return (
        <Feed
          id={id}
          isAdmin={isAdmin}
          fetchedContent={fetchedContent}
          rootCustomFields={rootCustomFields}
          overrides={overrides}
          curationIndices={curationIndices}
          noGrid={noGrid}
        />
      );
    default:
      // NOTE: The StoryCards() call for the "cards" and default
      // ("no-backing-content") cases are the same, hence they both
      // occur here in the default case
      return (
        <StoryCards
          {...{
            id,
            index: 0,
            isAdmin,
            fetchedContent,
            rootCustomFields,
            overrides,
            curationIndices,
            noGrid
          }}
        />
      );
  }
};

FlexFeature.label = defaults.displayName;
FlexFeature.featurePlugins = ["presets"];
FlexFeature.propTypes = FlexFeaturePropTypes;
export default FlexFeature;
